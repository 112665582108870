import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { atoms, splitProps, generateInlineVars } from '../../atoms/utils';
import { BoxType } from './types';
import { convertSemanticTokens, generateDisplay, generateDynamicClasses } from './helpers';

const Box = forwardRef<HTMLElement, BoxType>(
  ({ as: Element = 'div', children, className, hidden, htmlHidden, style, display, ...restProps }: BoxType, ref) => {
    const { stylingProps, nativeProps, tokenProps } = splitProps({
      ...restProps,
      Element,
      display: hidden ? generateDisplay(hidden, display) : display,
    });
    const atomicClass = atoms(stylingProps);
    const { vars, varsTokens } = generateInlineVars({ ...convertSemanticTokens(tokenProps) });
    const unrestrictedStyles = assignInlineVars(vars);

    return (
      <Element
        ref={ref}
        hidden={htmlHidden}
        className={classNames(generateDynamicClasses(varsTokens), atomicClass, className)}
        style={{ ...unrestrictedStyles, ...style }}
        {...nativeProps}
      >
        {children}
      </Element>
    );
  }
);

Box.displayName = 'Box';

export default Box;
