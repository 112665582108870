import { breakpoints } from '../shared/themeVars';
import type { Breakpoints } from './types';

export const RESPONSIVE_STYLES_BREAKPOINTS: Breakpoints = {
    default: '',
    sm: 'Sm',
    md: 'Md',
    lg: 'Lg',
    xl: 'Xl',
};

const { sm, md, lg, xl } = breakpoints;

export const RESPONSIVENESS = {
    default: {},
    sm: { '@media': sm },
    md: { '@media': md },
    lg: { '@media': lg },
    xl: { '@media': xl },
};

export const TOKENS = {
    letterSpacing: 'letterSpacing',
    top: 'top',
    bottom: 'bottom',
    right: 'right',
    left: 'left',
    templateAreas: 'templateAreas',
    gridArea: 'gridArea',
    gridTemplateAreas: 'gridTemplateAreas',
};

export const SELECTORS_TOKENS = {
    color: 'color',
    backgroundColor: 'backgroundColor',
    borderColor: 'borderColor',
    transform: 'transform',
    fill: 'fill',
};

export const RESPONSIVE_TOKENS = {
    width: 'width',
    maxWidth: 'maxWidth',
    minWidth: 'minWidth',
    height: 'height',
    maxHeight: 'maxHeight',
    minHeight: 'minHeight',
    flex: 'flex',
    flexShrink: 'flexShrink',
    flexGrow: 'flexGrow',
    flexBasis: 'flexBasis',
    order: 'order',
};

export const RESPONSIVE_AND_SELECTORS_TOKENS = {
    background: 'background',
};

export const CSS_NUMERIC_VALUES = {
    flex: 'flex',
    flexShrink: 'flexShrink',
    flexGrow: 'flexGrow',
    order: 'order',
};

export const FOCUS_TOKENS = {
    outlineColor: 'outlineColor',
};

export const THEME_TOKEN = {
    color: 'colors',
    backgroundColor: 'colors',
    borderColor: 'colors',
    outlineColor: 'colors',
    fill: 'colors',
};

export const SELECTORS = {
    default: '',
    hover: 'Hover',
    active: 'Active',
    focus: 'Focus',
};

export const FOCUSES = {
    default: '',
    focus: 'Focus',
    focusVisible: 'FocusVisible',
    focusWithin: 'FocusWithin',
};

export const RESPONSIVE_AND_SELECTORS = {
    ...SELECTORS,
    ...RESPONSIVE_STYLES_BREAKPOINTS,
};

export const DYNAMIC_TOKENS = {
    ...TOKENS,
    ...SELECTORS_TOKENS,
    ...RESPONSIVE_TOKENS,
    ...FOCUS_TOKENS,
    ...RESPONSIVE_AND_SELECTORS_TOKENS,
};
